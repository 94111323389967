import styled from "styled-components";
import {screen} from "common/styled-components/helpers/Mixins";

export const Wrapper = styled.div`
  ${({center}) => center && 'text-align: center'};
`

export const TitleTag = styled.h2`
  font-size: 72px;
  font-weight: 500;
  line-height: 1.2;

  ${screen()} {
    font-size: 48px;
  }
`