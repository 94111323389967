import {StyledIcon} from "common/styled-components/helpers";
import styled from "styled-components";

const Svg = styled(StyledIcon)``

export const IconWallet = (props) => (
    <Svg {...props} viewBox="0 0 100 80">
      <path fill="#fff" d="M54.17 33.33v12.5a12.5 12.5 0 0 0 12.5 12.5h29.16a11.4 11.4 0 0 0 4.17-.75v9.09a12.5 12.5 0 0 1-12.5 12.5h-75A12.5 12.5 0 0 1 0 66.67V12.5A12.5 12.5 0 0 1 12.5 0h75A12.5 12.5 0 0 1 100 12.5v9.08c-1.33-.5-2.74-.76-4.17-.75H66.67a12.5 12.5 0 0 0-12.5 12.5Z"/>
      <path fill="#fff" d="M95.83 29.17H66.67a4.17 4.17 0 0 0-4.17 4.16v12.5A4.17 4.17 0 0 0 66.67 50h29.16a4.17 4.17 0 0 0 4.17-4.17v-12.5a4.17 4.17 0 0 0-4.17-4.16ZM77.08 45.83a6.25 6.25 0 1 1 0-12.5 6.25 6.25 0 0 1 0 12.5Z"/>
    </Svg>
)