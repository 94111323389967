import {Label, Percentage} from "./featureitem.styled";
import {StyledFlex} from "common/styled-components/helpers";
// https://www.npmjs.com/package/react-countup
import CountUp from 'react-countup';

export const FeatureItem = (props) => {
  const {options, className} = props;

  return (
      <StyledFlex direction="column" className={className}>
        <Label>{options.label}</Label>
        <Percentage dotColor={options.color}>
          <CountUp end={options.percentage} duration={3} enableScrollSpy scrollSpyOnce />
          %
        </Percentage>
      </StyledFlex>
  )
}