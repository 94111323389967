import styled, {keyframes} from "styled-components";
import {hexToRgba, screen} from "common/styled-components/helpers";
import {etherium, planet, polygon} from "assets/images";
import {StyledFlex} from "common/styled-components/helpers";
import {MEDIA} from "common/constants";

const slideUpDownLeft = keyframes`
  0% {
    transform: translateY(0) translateX(-50%);
  }
  50% {
    transform: translateY(-30px) translateX(-50%);
  }
  100% {
    transform: translateY(0) translateX(-50%);
  }
`;

const slideUpDownRight = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
`;

const slideUpDownPolygon = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const Wrapper = styled.section`
  padding: ${({size}) => size === 'xl' ? '16rem 0 12rem' : '4rem 0'};

  border-bottom: 1px solid ${({theme}) => theme.colors.border};
  
  &:nth-of-type(2n) {
    background-color: ${({theme}) => theme.colors.mainBgLighten};
  }
  
  .main-title {
    margin-bottom: 8rem;
  }

  &.intro,
  &.faq {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      width: 129rem;
      height: 80rem;
      left: -377px;
      bottom: 0;

      transform: translateY(35%);

      background: radial-gradient(50% 50% at 50% 50%, rgba(133, 12, 227, 0.4) 0%, rgba(133, 12, 227, 0) 100%);
      z-index: 2;

      ${screen()} {
        width: 69rem;
        height: 40rem;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 148rem;
      height: 95rem;
      right: -377px;
      bottom: 0;

      transform: translateY(35%);

      background: radial-gradient(50% 50% at 50% 50%, rgba(237, 143, 1, 0.6) 0%, rgba(241, 145, 1, 0) 100%);
      z-index: 1;

      ${screen()} {
        width: 78rem;
        height: 45rem;
      }
    }
  }

  &.intro {
    ${StyledFlex} {
      position: relative;
      z-index: 4;

      ${screen(MEDIA.SIZE.MD, MEDIA.TYPE.MIN)} {
        min-height: calc(100vh - 13rem);
      }
    }

    .decor-left {
      position: absolute;
      width: 24.5rem;
      height: 15rem;
      left: 0;
      transform: translateX(-50%);
      bottom: 20%;
      border-radius: 100%;

      background: url(${planet}) no-repeat 100% 100%;
      background-size: cover;
      animation: ${slideUpDownLeft} 6s ease-in-out infinite;
      z-index: 3;

      ${screen()} {
        bottom: 14rem;
      }
    }

    .decor-right {
      position: absolute;
      width: 32rem;
      height: 32rem;
      right: -16rem;
      bottom: 30%;
      border-radius: 100%;

      background: linear-gradient(180deg, #5303A3 0%, rgba(83, 3, 163, 0) 100%);
      animation: ${slideUpDownRight} 12s ease-in-out infinite;
      z-index: 2;

      ${screen()} {
        bottom: 24rem;
      }
    }

    h1 {
      font-size: 8rem;
      font-weight: 800;

      ${screen()} {
        margin-top: 20.4rem;
      }
    }

    h3 {
      margin-top: 2rem;
      font-size: 2.4rem;
      font-weight: 500;
    }

    p {
      margin-top: 20.4rem;
      color: ${({theme}) => hexToRgba(theme.colors.light, 0.6)};
      max-width: 72.4rem;
      text-align: center;
      line-height: 2rem;

      ${screen(MEDIA.SIZE.MD, MEDIA.TYPE.MIN)} {
        position: absolute;
        bottom: 3rem;
        width: 100%;
      }

      ${screen()} {
        margin-top: 20.4rem;
      }
    }
  }

  &.info {
    ${StyledFlex} {
      min-height: 65rem;
    }
    
    &--table {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3rem;
        height: 3rem;
        border: 1px solid red;
      }
    }
    
    ${screen()} {
      ${StyledFlex} {
        flex-direction: column;
      }

      &--reverse {
        ${StyledFlex}.wrapper {
          flex-direction: column-reverse;
        }
      }
    }

    .image-wrapper {
      width: 100%;
      min-height: 65rem;

      ${screen()} {
        min-height: 35rem;
      }
    }

    .info-card {
      max-width: 45rem;
      flex-shrink: 0;
    }

    .info-item {
      &--table {
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;

          width: 8rem;
          height: 8rem;
        }

        &::before {
          top: 5rem;
          left: 6rem;
          background: url(${polygon}) no-repeat 100% 100%;
          background-size: cover;

          animation: ${slideUpDownPolygon} 16s ease-in-out infinite;
        }

        &::after {
          top: -3rem;
          right: 7rem;
          background: url(${etherium}) no-repeat 100% 100%;
          background-size: cover;

          animation: ${slideUpDownRight} 8s ease-in-out infinite;
        }

        ${screen()} {
          max-width: 45rem;
          margin-top: 10rem;

          &::after {
            right: 3rem;
          }
        }
      }

      &:not(:first-child) {
        margin-left: 2rem;
      }
    }
  }

  &.roadmaps {
    position: relative;
    overflow: hidden;
    padding-top: 16rem;
    padding-bottom: 12rem;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 12rem;
      left: 0;
      top: 0;

      background: linear-gradient(180deg, rgba(239, 144, 1, 0.4) 0%, rgba(239, 144, 1, 0) 100%);
      opacity: 0.2;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 8rem;
      left: 0;
      bottom: 0;

      background: linear-gradient(180deg, #7109CA 0%, rgba(113, 9, 202, 0) 100%);
      opacity: 0.2;
      transform: rotate(-180deg);
    }

    .roadmaps-list {
      align-self: normal;
      margin-top: 8rem;

      .roadmaps-item {
        &:not(:first-child) {
          margin-top: 6.4rem;
        }
      }
    }
  }

  &.features {
    ${screen()} {
      ${StyledFlex} {
        flex-direction: column;
      }
    }

    .features-list {
      .features-item {
        &:not(:first-child) {
          margin-top: 4rem;
        }
      }
    }
  }
`;