import i18n from "i18next";
import en from "./locales/en.json";
import { initReactI18next } from "react-i18next";
import ru from "./locales/ru.json";
import es from "./locales/es.json";
import LanguageDetector from "i18next-browser-languagedetector";

export const resources = {
  en,
  ru,
  es,
} as const;

i18n.use(initReactI18next).use(LanguageDetector).init({
  debug: true,
  resources,
  keySeparator: false,
  nsSeparator: ".",
});

export default i18n;
