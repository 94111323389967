import styled from "styled-components";
import {StyledEllipsis} from "common/styled-components/helpers";
import {screen} from "common/styled-components/helpers";
export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: ${({type}) => type === 'xs' ? '1.2rem' : '1.8rem'};
  font-weight: 500;
  font-family: inherit;
  padding: ${({type}) => type === 'xs' ? '0.8rem 1.5rem' : '1.6rem'};
  background: linear-gradient(180deg, #850CE3 0%, #5202A2 100%);
  border-radius: ${({type}) => type === 'xs' ? '7px' : '12px'};
  cursor: pointer;
  
  ${StyledEllipsis};
  
  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(180deg,#ac43ff,#5202a2);
  }
  
  ${screen()} {
    padding: ${({type}) => type === 'xs' ? '0.8rem' : '1.6rem'};
  }
`