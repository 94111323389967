export const MEDIA = {
  SIZE: {
    HD: 1920,
    XXL: 1660,
    XL: 1440,
    LG: 1200,
    MD: 992,
    SM: 768,
    XS: 576,
  },
  TYPE: {
    MIN: 'min',
    MAX: 'max',
  }
};