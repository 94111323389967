import {Wrapper} from "./button.styled";
import {NavLink} from 'react-router-dom';

export const Button = (props) => {
  const {link, children, className, to, target, type} = props;

  return to ? (
      <Wrapper as={NavLink} to={to ? to : undefined} className={className} type={type}>
          {children}
      </Wrapper>
  ) : (
      <Wrapper
          as={link ? 'a' : 'button'}
          href={link ? link : undefined}
          target={target ? target : undefined}
          type={type}
          className={className}
      >
        {children}
      </Wrapper>
  )
}