import {StyledIcon} from "common/styled-components/helpers";
import styled from "styled-components";

const Svg = styled(StyledIcon)``

export const IconArrowDown = (props) => (
    <Svg {...props}width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect width="16" height="16" rx="4" fill="white" fillOpacity="0.05"/>
      <path d="M4 6L7.43432 9.43432C7.74673 9.74673 8.25327 9.74673 8.56569 9.43431L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    </Svg>
)