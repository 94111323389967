import styled from "styled-components";
import {StyledFillImage} from "common/styled-components/helpers";

export const Wrapper = styled.div``

export const Picture = styled.picture`
  position: relative;
  overflow: hidden;

  width: ${({width}) => width ? width : '10rem'};
  height: ${({height}) => height ? height : '10rem'};
`
export const Source = styled.source`${StyledFillImage}`
export const Img = styled.img`${StyledFillImage}`