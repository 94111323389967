import styled from "styled-components";
import {hexToRgba} from "common/styled-components/helpers";

export const Wrapper = styled.span`
  display: inline-block;
  background-color: ${hexToRgba('#ED8F01', 0.1)};
  color: ${({theme}) => theme.colors.accent};
  border-radius: 8px;
  padding: 0.5rem 1rem;

  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.3rem;
`