import styled from "styled-components";

export const Label = styled.span`
  color: ${({theme}) => theme.colors.text.gray};
`

export const Percentage = styled.span`
  position: relative;
  margin-top: 0.8rem;
  padding-left: 2.8rem;
  font-size: 2.4rem;
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    background-color: ${({dotColor}) => dotColor ? dotColor : 'currentColor'};
    transform: translateY(-50%);
  }
`
