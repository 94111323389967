import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import i18n from 'i18next';

const options = [{ language: 'en' }, { language: 'es' }, { language: 'ru' }];

const LanguageSwitcher = ({className}: any) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);
  const [language, setCurrentLanguage] = useState<any>(localStorage.getItem('lang') || 'en');

  const handleChange = (language: string) => {
    setCurrentLanguage(language);
    localStorage.setItem('lang', language);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (!(ref.current === e.target || ref?.current?.contains(e.target as Node))) {
        setOpen(false);
      }
    };
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [setOpen]);

  return (
      <div className={`languageSwitcher ${className}`}>
        <button ref={ref} className="languageSwitcher__button" onClick={() => setOpen(!open)}>
          <div className="languageSwitcher__country">
            {language && (<img src={`/images/flags/${language}.svg`} alt=""/>)}
            {' '}
            {language}
          </div>
          <span className="dropdown__arrow">
          <img className={`dropdown__arrowImage ${open ? 'dropdown__arrowImage_rotated' : ''}`}
               src="/images/arrow-down.svg" alt="dropdown__arrow"/>
          </span>
          <ul className="languageSwitcher__languagesList">
            {open && options?.map((user: { language: string }, index: number) => (
                <li key={index} onClick={() => handleChange(user.language)}>
                  <img src={`/images/flags/${user.language}.svg`} alt={`${user.language}`}/>
                  {user.language}
                </li>
            ))}
          </ul>
        </button>
      </div>
  );
};

export default LanguageSwitcher;