import {createGlobalStyle} from "styled-components";
import {screen} from "./Mixins";
import {MEDIA} from "common/constants";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 2vw;
    
    ${screen(MEDIA.SIZE.MD, MEDIA.TYPE.MIN)} {
      font-size: ${({theme}) => theme.fonts.defaultSizeRem}px;
    }
  }
  
  html,
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    
    overflow-x: hidden;
  }
  
  body {
    font-family: ${({theme}) => theme.fonts.fontFamily};
    font-size: ${({theme}) => theme.fonts.defaultSize}rem;
    color: ${({theme}) => theme.colors.light};
    background-color: ${({theme}) => theme.colors.mainBg};
    
    #root {
      display: flex;
      flex-direction: column;

      min-height: 100vh;
    }
    
    footer {
      margin-top: auto;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  ul,
  ol,
  li,
  tr,
  td,
  th,
  table,
  blockquote {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: inherit;
    font-weight: inherit;
  }

  ul,
  ol {
    list-style: none;
  }

  span {
    font-style: inherit;
    font-family: inherit;
    font-weight: inherit;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
  
  button {
    border: 0;
    font-size: inherit;
  }
  
  a,
  label,
  span,
  input,
  button {
    display: inline-block;
    background-color: transparent;
    box-sizing: border-box;
  }
  
  picture {
    display: inline-block;
  }

  .content-zone {
    overflow-wrap: anywhere;
    font-size: 1.4rem;
    line-height: 1.3;
    
    h1,h2,h3,h4,h5,h6 {
      color: ${({theme}) => theme.colors.title};

      & + p {
        margin-top: 0.5rem;
      }
    }
  }
`

export default GlobalStyle;