import {StyledIcon} from "common/styled-components/helpers";
import styled from "styled-components";

const Svg = styled(StyledIcon)``

export const IconExchange = (props) => (
    <Svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.53845 5.53197L9.53845 20.9655C9.53845 21.2399 9.42905 21.503 9.2343 21.697C9.03955 21.891 8.77541 22 8.5 22C8.22458 22 7.96045 21.891 7.7657 21.697C7.57095 21.503 7.46154 21.2399 7.46154 20.9655L7.46154 5.53197L5.7728 7.21429C5.57805 7.4083 5.3139 7.5173 5.03848 7.5173C4.76306 7.5173 4.49892 7.4083 4.30416 7.21429C4.10941 7.02029 4 6.75716 4 6.48279C4 6.20842 4.10941 5.94529 4.30416 5.75128L7.76568 2.30301C7.86211 2.20694 7.97659 2.13074 8.10259 2.07875C8.22858 2.02676 8.36362 2 8.5 2C8.63638 2 8.77142 2.02676 8.89741 2.07875C9.02341 2.13074 9.13789 2.20694 9.23432 2.30301L12.6958 5.75128C12.8906 5.94529 13 6.20842 13 6.48279C13 6.75716 12.8906 7.02029 12.6958 7.21429C12.5011 7.4083 12.2369 7.5173 11.9615 7.5173C11.6861 7.5173 11.422 7.4083 11.2272 7.21429L9.53845 5.53197Z" fill="#F19101"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.4615 18.468L15.4615 3.03448C15.4615 2.76012 15.571 2.497 15.7657 2.30299C15.9604 2.10899 16.2246 2 16.5 2C16.7754 2 17.0396 2.10899 17.2343 2.30299C17.429 2.497 17.5385 2.76012 17.5385 3.03448L17.5385 18.468L19.2272 16.7857C19.422 16.5917 19.6861 16.4827 19.9615 16.4827C20.2369 16.4827 20.5011 16.5917 20.6958 16.7857C20.8906 16.9797 21 17.2428 21 17.5172C21 17.7916 20.8906 18.0547 20.6958 18.2487L17.2343 21.697C17.1379 21.7931 17.0234 21.8693 16.8974 21.9212C16.7714 21.9732 16.6364 22 16.5 22C16.3636 22 16.2286 21.9732 16.1026 21.9212C15.9766 21.8693 15.8621 21.7931 15.7657 21.697L12.3042 18.2487C12.1094 18.0547 12 17.7916 12 17.5172C12 17.2428 12.1094 16.9797 12.3042 16.7857C12.4989 16.5917 12.7631 16.4827 13.0385 16.4827C13.3139 16.4827 13.578 16.5917 13.7728 16.7857L15.4615 18.468Z" fill="#6A07C0"/>
    </Svg>
)