import styled, {keyframes} from "styled-components";
import {screen} from "common/styled-components/helpers";
import {MEDIA} from "common/constants";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const fully = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

export const Card = styled.div`
  background: ${({theme}) => theme.colors.mainBg};
  border-radius: 16px;
  padding: 3.2rem;
  border: 1px solid transparent;

  transition: border-color 1s ease-in 1s;

  .is-animate & {
    border-color: ${({theme}) => theme.colors.border};
  }
  
  h4 {
    font-weight: 700;
    font-size: 2.4rem;
    text-transform: uppercase;
    
    sup {
      font-size: 1.2rem;
    }
  }
  
  p {
    font-weight: 500;
    color: ${({theme}) => theme.colors.text.gray};
    margin-top: 1.6rem;
    line-height: 2rem;
  }
`

export const Num = styled.div`
  position: relative;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 5.6rem;
  height: 5.6rem;
  
  border-radius: 100%;
  background-color: ${({theme}) => theme.colors.primary};
  
  font-size: 3.2rem;
  
  &::before {
    content: '';
    position: absolute;
    border-radius: 100%;

    width: 8rem;
    height: 8rem;

    background: linear-gradient(180deg, rgba(133, 12, 227, 0.4) 0%, rgba(82, 2, 162, 0.4) 100%);
    z-index: -1;

    animation: ${pulse} 4s infinite;
  }
`

export const Wrapper = styled.div`
  position: relative;

  ${screen(MEDIA.SIZE.MD, MEDIA.TYPE.MIN)} {
    &::before {
      content: '';
      position: absolute;
      max-width: 90%;
      top: 50%;
      ${({reversed}) => reversed ? 'left' : 'right'}: 8rem;
      width: 0;
      height: 2px;

      transform: translateY(-50%);

      background:
          linear-gradient(${({reversed}) => reversed ? '270deg' : '90deg'},
          ${({theme}) => theme.colors.primary} 50%, transparent 100%);
      z-index: 0;

      .is-animate & {
        animation: ${fully} 6s forwards;
      }
    }
  }

  ${screen()} {
    .react-reveal + .react-reveal {
      animation-name: none !important;
    }
  }
  
  ${Card} {
    ${screen()} {
      padding-left: 15rem;
    }
    
    ${screen(MEDIA.SIZE.MD, MEDIA.TYPE.MIN)} {
      position: relative;
      max-width: 52.1rem;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        ${({reversed}) => reversed ? 'left' : 'right'}: -6px;
        width: 90%;
        height: 6.4rem;

        transform: translateY(-50%);
        background-color: ${({theme}) => theme.colors.primary};
        border-radius: 10px;

        z-index: -1;
      }
    }
  }
  
  ${Num} {
  ${screen()} {
    position: absolute;
    left: 4.5rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.5s ease-in 1s;

    .is-animate & {
      opacity: 1;
    }
  }
}
`