import { FieldProps } from "formik";
import React, { InputHTMLAttributes, VFC } from "react";
import "./style.scss";

export type TextInputProps = {
  label?: string;
} & FieldProps &
  InputHTMLAttributes<HTMLInputElement>;

const FormInput: VFC<TextInputProps> = ({
  field,
  type,
  placeholder,
  className = "",
  form,
  label = "",
}) => {
  const { touched, errors } = form;
  const haveError = touched[field.name] && errors[field.name];
  const errorText: any = errors[field?.name]!;

  return (
    <>
      <div className={`text-input__wrapper`}>
        <input
            className={`text-input ${className} ${
                haveError ? "text-input--error" : ""
            }`}
            type={type}
            placeholder={placeholder}
            {...field}
        />
      </div>
      {haveError && (
        <div className="text-input__error-message">{errorText}</div>
      )}
    </>
  );
};
export default FormInput;
