import {StyledIcon} from "common/styled-components/helpers";
import styled from "styled-components";

const Svg = styled(StyledIcon)``

export const IconLogout = (props) => (
    <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M5.83983 14.8467H5.92649C8.88649 14.8467 10.3132 13.68 10.5598 11.0667C10.5865 10.7933 10.3865 10.5467 10.1065 10.52C9.83983 10.4933 9.58649 10.7 9.55983 10.9733C9.36649 13.0667 8.37983 13.8467 5.91983 13.8467H5.83316C3.11983 13.8467 2.15983 12.8867 2.15983 10.1733V5.82665C2.15983 3.11332 3.11983 2.15332 5.83316 2.15332H5.91983C8.39316 2.15332 9.37983 2.94665 9.55983 5.07999C9.59316 5.35332 9.82649 5.55999 10.1065 5.53332C10.3865 5.51332 10.5865 5.26665 10.5665 4.99332C10.3398 2.33999 8.90649 1.15332 5.92649 1.15332H5.83983C2.56649 1.15332 1.16649 2.55332 1.16649 5.82665V10.1733C1.16649 13.4467 2.56649 14.8467 5.83983 14.8467Z" fill="#FFFFFF"/>
      <path d="M5.99976 8.5H13.5864C13.8598 8.5 14.0864 8.27333 14.0864 8C14.0864 7.72667 13.8598 7.5 13.5864 7.5H5.99976C5.72643 7.5 5.49976 7.72667 5.49976 8C5.49976 8.27333 5.72643 8.5 5.99976 8.5Z" fill="#FFFFFF"/>
      <path d="M12.0999 10.7333C12.2265 10.7333 12.3532 10.6866 12.4532 10.5866L14.6865 8.35331C14.8799 8.15998 14.8799 7.83998 14.6865 7.64664L12.4532 5.41331C12.2599 5.21998 11.9399 5.21998 11.7465 5.41331C11.5532 5.60664 11.5532 5.92664 11.7465 6.11998L13.6265 7.99998L11.7465 9.87998C11.5532 10.0733 11.5532 10.3933 11.7465 10.5866C11.8399 10.6866 11.9732 10.7333 12.0999 10.7333Z" fill="#FFFFFF"/>
    </Svg>
)