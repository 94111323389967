import styled, {css} from "styled-components";
import {MEDIA} from "common/constants";

export const StyledFillImage = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 0;
  min-width: 100%;
  min-height: 100%;
`

export const StyledEllipsis = css`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${({direction}) => direction || 'row' };
  justify-content: ${({justify}) => justify || 'stretch'};
  align-items: ${({align}) => align || 'stretch'};
`

export const StyledContainer = styled.div`
  width: ${({width, theme}) => width || `${theme.container.width}rem`};
  padding-left: ${({containerGap, theme}) => containerGap || `${theme.container.gap}rem`};
  padding-right: ${({containerGap, theme}) => containerGap || `${theme.container.gap}rem`};
  margin-top: ${({center}) => center && 'auto'};
  margin-bottom: ${({center}) => center && 'auto'};
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  box-sizing: border-box;
`

export const StyledIcon = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
  width: ${({width}) => width || '1.5rem'};
  height: ${({height}) => height || '1.5rem'};
  fill: ${({fill}) => fill || 'inherit'};
  
  path {
    transition: fill ${({theme}) => theme.transition};
  }
`
export const hexToRgba = (hexColor, alpha) => {
  const hex = hexColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const screen = (size = MEDIA.SIZE.MD, type = MEDIA.TYPE.MAX) => {
  return `@media only screen and (${type}-width: ${size}px)`;
}