import styled from "styled-components";
import {StyledFillImage} from "common/styled-components/helpers";

export const Img = styled.img`${StyledFillImage};`
export const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  
  font-weight: 700;
`

export const ImgWrapper = styled.span`
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  display: inline-block;
  margin-right: 1rem;
`