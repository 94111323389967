import {StyledIcon} from "common/styled-components/helpers";
import styled from "styled-components";

const Svg = styled(StyledIcon)``

export const IconSwap = (props) => (
        <Svg {...props} viewBox="0 0 25 24">
          <g clipPath="url(#a)">
            <path fill="#633001" fillRule="evenodd"
                  d="M5.24 3.75A3.15 3.15 0 0 1 8.3 0a3.17 3.17 0 0 1 3.15 3.15v3.9c.45 0 .9-.08 1.35-.08.45 0 .83 0 1.28.08v-3.9A3.17 3.17 0 0 1 17.24 0c1.95 0 3.45 1.8 3.15 3.75l-.83 4.57c2.93 1.28 5.18 3.53 5.18 6.3v1.73c0 2.32-1.5 4.27-3.68 5.55a15.65 15.65 0 0 1-8.25 2.1c-3.22 0-6.07-.75-8.25-2.1-2.1-1.27-3.6-3.22-3.6-5.55v-1.73c0-2.77 2.18-5.02 5.1-6.3l-.82-4.57Zm13.2 5.18.97-5.4A2.22 2.22 0 0 0 17.24.9c-1.2 0-2.18.97-2.18 2.18v4.95c-.3-.08-.67-.08-.97-.08-.45 0-.83-.08-1.28-.08-.45 0-.9 0-1.35.08-.3 0-.67.08-.97.08V3.15a2.17 2.17 0 1 0-4.27.45L7.18 9c-3.15 1.2-5.25 3.3-5.25 5.7v1.73c0 3.67 4.87 6.67 10.87 6.67 6 0 10.88-3 10.88-6.68V14.7c.07-2.47-2.03-4.57-5.25-5.77Z"
                  clipRule="evenodd"/>
            <path fill="#FEDC90" d="M23.76 16.35c0 3.67-4.87 6.67-10.87 6.67-6 0-10.88-3-10.88-6.67v-1.73h21.83v1.73h-.08Z"/>
            <path fill="#D1884F" fillRule="evenodd"
                  d="M6.21 3.6A2.17 2.17 0 0 1 8.31.97c1.2 0 2.18.98 2.18 2.18V8.1c.75-.08 1.5-.15 2.32-.15.75 0 1.5.07 2.25.15V3.15c0-1.2.98-2.18 2.18-2.18 1.35 0 2.4 1.28 2.17 2.63L18.44 9c3.15 1.2 5.32 3.3 5.32 5.7 0 3.68-4.87 6.68-10.87 6.68-6 0-10.88-3-10.88-6.68 0-2.4 2.1-4.5 5.25-5.7L6.21 3.6Z"
                  clipRule="evenodd"/>
            <path fill="#633001"
                  d="M9.81 14.18c0 .97-.52 1.8-1.2 1.8-.67 0-1.2-.83-1.2-1.8 0-.98.53-1.8 1.2-1.8.68 0 1.2.82 1.2 1.8Zm8.33 0c0 .97-.53 1.8-1.2 1.8-.68 0-1.2-.83-1.2-1.8 0-.98.52-1.8 1.2-1.8.67 0 1.2.82 1.2 1.8Z"/>
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M.96 0h23.77v24H.96z"/>
            </clipPath>
          </defs>
        </Svg>
)