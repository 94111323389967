import { useEffect } from 'react';

interface IProps {
  containerRef: any;
  open: boolean;
  onOpen: (open: boolean) => void;
  children: any;
}

export const Popup = ({ containerRef, open, onOpen, children }: IProps) => {
  useEffect(() => {
    // @ts-ignore
    const handleClickOutside = (e: any) => !containerRef?.current?.contains(e.target) && onOpen(false);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, onOpen]);

  if (!open) {
    return null;
  }

  return children;
};

export default Popup;