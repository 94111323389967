import styled from "styled-components";
import {hexToRgba} from "common/styled-components/helpers";

export const List = styled.ul`
display: flex;
`

export const ListItem = styled.li`
  position: relative;
  padding: 2rem;
  color: ${({active, theme}) => active ? theme.colors.light : hexToRgba(theme.colors.light, 0.6)};
  
  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({active, theme}) => active ? theme.colors.primary : 'transparent'};
  }
`